.custom-ng-select {
	.ng-select.ng-select-single .ng-select-container {
		height: 38px;
	}
}
.outline-label.form-group {
	position: relative;
	label {
		position: absolute;
		bottom: 7px;
		left: 9px;
		color: $slr-gray-2;
		font-size: 14px;
		font-weight: 400;
		pointer-events: none;
	}
	.ng-value-container {
		input {
			background: white !important;
		}
	}
	input,
	textarea {
		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			+ label {
				transform: translateY(-22px);
				transition: 150ms;
				width: fit-content;
				padding: 0px 7px;
				font-size: 12px;
				background-color: $slr-white;
				color: $slr-blue-2;
			}
			+ .mandatory {
				@extend .required;
			}
		}
		&:focus {
			background-color: transparent !important;
		}
	}
	textarea {
		+ label {
			top: 7px;
			bottom: auto;
		}
		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			+ label {
				transform: translateY(-16px);
			}
		}
	}
	.ng-select.ng-select-focused {
		.ng-select-container {
			border-color: $slr-blue-2;
		}
	}
	.ng-select.ng-select-focused,
	.ng-select.custom-ng-select {
		.ng-select-container {
			z-index: 1;
		}
		+ .label {
			transform: scale(0.75) translateY(-22px);
			transition: 150ms;
			width: fit-content;
			padding: 0px 7px;
			left: -4px;
			font-size: 16px;
			background-color: $slr-white;
			z-index: 2;
		}
		+ .mandatory {
			@extend .required;
		}
	}
	.ng-select.ng-select-focused {
		+ .label {
			color: $slr-blue-2;
		}
	}
	.custom-ng-select {
		input {
			background: transparent !important;
		}
	}
}
.broadcast-popup {
	.mat-form-field-wrapper {
		margin-top: 0 !important;
	}
}
