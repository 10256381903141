// Small devices (landscape phones, 576px and up)

@media (max-width: 576px) {
	// Resource center start
	.res_mobile {
		.justify-content-center {
			justify-content: left !important;
		}
		ps-4 {
			padding-left: 0px !important;
		}
		.form-group {
			width: 100% !important;
		}
		.d-flex {
			display: block !important;
		}
		.border-end {
			border-right: transparent !important;
			margin-bottom: 20px !important;
		}
	}
	.mobilechatpopup {
		width: 100% !important;
		position: static;
		margin-left: 0% !important;
		margin-top: -160px !important;
		height: 50%;
	}
	// Resource center end
	.blockmobile {
		&.d-flex {
			display: block !important;
		}
	}
	.exempt-year-dropdown {
		width: 103px;
	}
	.surveyinput {
		width: 51%;
	}

	#sidebar i {
		padding-left: 8px !important;
	}

	.preferences {
		height: 100% !important;
	}
	.btn {
		font-size: 12px !important;
	}
	.content-header {
		font-size: 18px !important;
		margin-bottom: 10px;
	}
	.search {
		.ng-select {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.toggle-tabs {
		li {
			font-size: 13px !important;
			width: 50%;
		}
	}
	.search-box {
		.input-group {
			margin-top: 10px;
			input {
				font-size: 12px;
			}
		}
	}
	.past-summary {
		ul {
			background: $slr-white;
			padding: 10px !important;
			border-radius: 5px;
			box-shadow: 0px 2px 6px $slr-gray-5;
			li {
				margin-bottom: 10px;
				font-size: 13px !important;
			}
		}
	}
	.global-table-wrap {
		table {
			thead {
				tr {
					th {
						font-size: 12px;
						span {
							display: none;
						}
					}
				}
			}
			tbody {
				tr {
					td {
						font-size: 12px;
						span {
							&.courses-test {
								display: none;
							}
							&.test {
								display: contents;
							}
						}
					}
				}
			}
		}
	}
	.live-event-table {
		table {
			thead {
				tr {
					th {
						font-size: 12px;
						span {
							&.sort-icons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.assign-wrap {
		h6 {
			font-size: 16px !important;
			margin-bottom: 15px !important;
		}
	}
	.limit-dropdown {
		margin-bottom: 15px !important;
	}
	.main-content {
		.list-calendar-wrap {
			ul {
				margin-top: 20px;
				background: $slr-white;
				padding: 10px !important;
				border-radius: 5px;
				box-shadow: 0px 2px 6px $slr-gray-5;
			}
		}
		.actions-div {
			background: $slr-white;
			padding: 10px;
			border-radius: 7px;
			box-shadow: 0px 2px 6px $slr-gray-5;
			overflow: hidden;
			margin-top: 0px !important;
		}
		.width-fit {
			width: -webkit-fill-available !important;
		}
		.card {
			span {
				&.mobile-res {
					text-align: left;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 140px;
				}
			}
		}
	}
	.header-options {
		span {
			&.mobile-noti {
				display: none !important;
			}
		}

		.menu-items-hide {
			display: block !important;
		}
	}

	// header part css
	.header-options {
		span {
			&.mobile-noti {
				display: none !important;
			}
		}
		.header-options-profile {
			margin-left: -10px !important;
			border: none !important;
			padding-left: 20px !important;
		}
	}
	.header-branding {
		span {
			&.mobile-head {
				display: flex;
			}
		}
		img {
			height: 34px;
		}
		.navbar-brand {
			margin-right: 10px !important;
		}
		.logo {
			height: 25px !important;
			padding: 0px 10px;
		}
		.ng-select-container {
			width: 95px !important;
			height: 35px !important;
		}
		.child-location-header {
			padding: 8px 8px !important;
			border-radius: 10px !important;
		}
	}
	// header part end
	// Resoures center css start
	.table-header {
		.table-header-groups {
			font-size: 13px;
		}
	}
	.categories-accordion {
		.card {
			.card-body {
				.course-checkboxes {
					margin-left: 0px !important;
				}
			}
			.courses-link {
				.course-details {
					font-size: 12px;
				}
				.client-code {
					overflow-wrap: anywhere;
				}
			}
		}
	}
	.resource-centre-actions {
		.dropdown-div {
			width: 100% !important;
		}
	}
	// Resoures center css end
	//    modalpopup resources center css
	.modal-content {
		.modal-title {
			font-size: 18px !important;
		}
		.courses-header {
			padding-left: 10px;
		}
		.course-list {
			padding-left: 10px;
		}
		.sub-heading {
			padding-left: 10px;
		}
	}

	// Live event Calendar
	.live-event-calendar {
		.fc .fc-toolbar {
			flex-direction: column;
		}
	}

	// For Change Supervisors and Change ed Groups
	.change-con {
		align-items: start !important;
		flex-direction: column;
		gap: 0 !important;
		h5 {
			margin-bottom: 0;
		}
		slr-drp01 {
			margin-bottom: 5px;
		}
	}

	// create and edit badge section
	.badge-reward-preview {
		width: auto;
	}

	.new-edit-badge {
		.pd-10 {
			padding: 0px 10px 0px 10px;
		}
	}

	.resource-centre-assignments {
		.specific-year {
			width: 47% !important;
		}

		.view-assign-custom-year {
			width: 50% !important;
		}

		.input-group {
			width: 55% !important;
		}
	}

	.show-help-width {
		width: 80% !important;
		.mob-help-close {
			padding-right: 1rem !important;
		}
	}

	.user-assign-delete-popup {
		width: 80vw !important;
	}

	.notification-mobile-chat-popup {
		margin-left: 0px !important;
		margin-top: 0px !important;
		width: 70%;
	}

	.types-section {
		justify-content: unset !important;
		width: 100%;
		overflow-x: auto;
	}

	.view-assign-user-reason {
		width: auto !important;
	}

	.edu-history-legends {
		justify-content: space-between !important;
		flex-wrap: wrap !important;
		.pad-4 {
			padding: 4px !important;
		}
	}
	.dashboard {
		.host-description {
			flex-direction: column;
			align-items: start !important;
		}
		.register-btn-res {
			float: left;
			margin-top: 0;
		}
	}

	.break-name {
		word-break: normal;
	}
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
	.filter-container {
		.search-course {
			width: 360px !important;
		}
	}
	.footer-wrapper {
		.footer-links {
			display: none;
		}
		.footer-items {
			display: none;
		}
		.copyrights {
			text-align: center;
		}
		.contact-links {
			.bullet {
				display: none;
			}
		}
	}
	.inner-details-wrap {
		flex-direction: column;
	}
	.configuration-div {
		flex-direction: column;
		.left-navigation {
			min-height: fit-content !important;
			border-right: none !important;
			width: 100%;
		}
		.content-area {
			width: 100%;
		}
	}
	.res-filter-direction {
		flex-direction: column !important;
	}
	.res-full-width {
		width: 100% !important;
	}
	.res-text-center {
		text-align: center !important;
	}
	.res-clear-padding {
		padding: 0 !important;
	}
	.res-clear-margin {
		margin: 0 !important;
	}

	// create and edit badge section
	.badge-reward-preview {
		width: auto;
	}

	.new-edit-badge {
		.pd-10 {
			padding: 0px 10px 0px 10px;
		}
	}

	.menu-items-hide {
		display: none !important;
	}
}

// Medium devices (768px and up)

@media (min-width: 768px) {
	.badge-reward-preview {
		width: auto !important;
	}
}

@media (max-width: 1000px) {
	.categories-accordion {
		.btn-link {
			height: fit-content;
		}
	}

	.menu-items-hide {
		display: none !important;
	}
}

// large devices (desktops, 1001px and above)
@media (min-width: 1001px) {
	// header menu css
	.menu-items-hide {
		display: none !important;
	}
}
