@import "./colors.scss";

.cp {
	cursor: pointer;
}

.overflow-y-scroll {
	height: 100%;
	overflow-y: auto;
}

// ng-select css
.ng-select {
	font-size: 14px;
}
.formpl {
	padding-left: 1.5rem !important;
}
// margin right css
.mr-20 {
	margin-right: 20px;
}
.mr-30 {
	margin-right: 30px;
}
// margin left css

.ml-30 {
	margin-left: 30px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-15 {
	margin-left: 15px;
}

// margin bottom css

.mb-10 {
	margin-bottom: 10px;
}
.mb-20 {
	margin-bottom: 20px;
}
// margin top css

.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-50 {
	margin-top: 50px;
}
// margin right css
.mr-10 {
	margin-right: 10px;
}

// width-fit css
.width-fit {
	width: fit-content;
}

// padding css
.pd-0 {
	padding: 0px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-15 {
	padding-bottom: 20px;
}
.pl-15 {
	padding-left: 15px;
}

.live-check {
	vertical-align: sub;
	margin-right: 10px;
}
// global table css
.global-table-wrap {
	table {
		// table-layout: fixed;
		font-size: 13px;
		margin-top: 20px;
		box-shadow: 0px 2px 6px $slr-gray-5;
		border-radius: 5px;
		border-collapse: collapse;
		thead {
			tr {
				background-color: $slr-gray-1;
				color: $slr-gray-6;
				font-size: 14px;
				border-radius: 5px;
				th {
					padding: 12px;
					// border-bottom: transparent;
					border: 1px solid $slr-gray-10;
					border-top: none;
					span {
						margin-left: 10px;
						font-size: 16px;
						color: $slr-white;
						top: 4px;
						position: relative;
					}
					&:first-child {
						border-top-left-radius: 5px;
						border-left: none;
					}
					&:last-child {
						border-top-right-radius: 5px;
						border-right: none;
					}
				}
			}
			&.sticky-top {
					position: sticky;
					top: 0;
			}
		}
		tbody {
			tr {
				background-color: $slr-white;
				color: $slr-gray-6;
				font-size: 14px;
				border-radius: 5px;
				td {
					padding: 12px;
					border: 1px solid $slr-gray-10;
					vertical-align: middle;
					line-height: 15px;
					&.bor-red {
						border-left: 12px solid $slr-orange;
					}
					&.bor-yellow {
						border-left: 12px solid $slr-yellow;
					}
					&.bor-blue {
						border-left: 12px solid $slr-blue-1;
					}
					&.bor-green {
						border-left: 12px solid $slr-green-0;
						&.width10 {
							width: 10px;
						}
					}
					span {
						color: $slr-blue-1;
						margin-left: 10px;
						top: 5px;
						position: relative;
						&.courses-test {
							border-radius: 20px;
							background-color: $slr-yellow;
							font-size: 10px;
							font-weight: 600;
							color: $slr-black;
							padding: 2px 10px;
							vertical-align: super;
							text-transform: uppercase;
						}
						&.test {
							vertical-align: super;
							color: $slr-gray-2;
						}
					}
					.logic-cell-data span {
						margin-left: 0px;
						font-size: 16px;
						color: $slr-black;
						top: 0px;
						position: relative;
					}
					.custom-icon {
						color: inherit;
					}
					a {
						color: $slr-blue-1;
						text-decoration: none;
						font-size: 13px;
					}
					hr {
						height: 0px;
						margin: 0px;
					}
				}
				&:hover {
					background-color: $slr-blue-0;
				}
			}
			&:last-child {
				border-top-right-radius: 5px;
			}
		}
		&.lms {
			thead {
				tr {
					background-color: $slr-blue-2;
					th {
						color: $slr-white;
					}
				}
			}
		}
		&.qrm {
			thead {
				tr {
					background-color: $slr-red-1;
					th {
						color: $slr-black;
						.sort-icons {
							color: $slr-black;
						}
					}
				}
			}
		}
	}
}

// live-event-table start
.live-event-table {
	.card {
		margin-bottom: 1px;
		border: none;
		box-shadow: 0px 2px 6px $slr-gray-5;
		.card-header {
			padding: 10px 4px;
			background-color: $slr-white;
			border: none;
			border-left: 6px solid $slr-blue-6;
			overflow: hidden;
			button {
				width: 100%;
				text-align: left;
				color: $slr-black;
				text-decoration: none;
				padding-left: 15px;
				font-size: 13px;
				&:focus {
					box-shadow: none;
				}
			}
			.table-header-groups {
				span {
					background: $slr-blue-0;
					padding: 2px 7px;
					border-radius: 40px;
					color: $slr-blue-2;
					font-size: 11px;
				}
			}
		}
		.card-body {
			padding-left: 40px;
			.section-part {
				font-size: 13px;
				font-weight: 700;
				border-top: 1px solid $slr-gray-1;
				padding-top: 8px;
				ul {
					list-style: none;
					padding: 0px;
					margin: 10px 0px 20px 0px;
					li {
						font-weight: 500;
						margin-right: 35px;
						float: left;
						margin-bottom: 20px;
						img {
							width: 20px;
							vertical-align: bottom;
							margin-right: 5px;
						}
					}
				}
				.add-new {
					border-top: 1px solid $slr-gray-1;
					padding-top: 10px;
					a {
						text-decoration: none;
						color: $slr-blue-2;
					}
				}
			}
		}
	}
	table {
		width: 100%;
		thead {
			tr {
				background-color: $slr-gray-1;
				color: $slr-gray-6;
				border-radius: 5px;
				th {
					padding: 12px;
					width: 16.6%;
					border-bottom: transparent;
					color: $slr-black;
					font-size: 14px;
					span {
						&.sort-icons {
							margin-left: 10px;
							font-size: 13px;
							color: $slr-gray-2;
							top: 4px;
							position: relative;
						}
					}
					&:first-child {
						border-top-left-radius: 5px;
					}
					&:last-child {
						border-top-right-radius: 5px;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					width: 16.6%;
				}
			}
		}
	}
}
// live-event-table end

// create-live-event
.event-details-formgroup {
	.user-list {
		font-size: 13px;
		margin-left: 10px;
	}
	.remove-user-icon {
		font-size: 16px;
		margin-right: 10px;
		color: $slr-red-0;
		cursor: pointer;
	}
	.remove-icon {
		font-size: 16px;
		color: $slr-red-0;
		cursor: pointer;
		top: 0px;
		position: relative;
		left: 10px;
	}
}

.inner-details-wrap {
	// margin: 20px 0;
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	.box-inner {
		background-color: $slr-white;
		border-radius: 5px;
		padding: 10px;
		box-shadow: 0px 2px 6px $slr-gray-1;
		.head-content {
			font-size: 20px;
			font-weight: 800;
			span {
				font-size: 12px;
				display: block;
				font-weight: 500;
				color: $slr-gray-2;
				&.orange-color {
					color: $slr-yellow;
					font-size: 20px;
					font-weight: 800;
				}
			}
		}
	}
}

// past due css

.past-summary {
	ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
		float: right;
		li {
			font-size: 14px;
			display: inline-block;
			margin-right: 20px;
			text-align: right;
			span {
				&.red-color {
					// background: $slr-orange;
					padding: 0px 8px 0px 10px;
					margin-right: 8px;
					border-radius: 5px;
				}
				&.yellow-color {
					background: $slr-yellow;
					padding: 0px 8px 0px 10px;
					margin-right: 8px;
					border-radius: 5px;
				}
				&.material-icons {
					margin-right: 8px;
					top: 5px;
					position: relative;
					color: $slr-blue-1;
				}
			}
			img {
				margin-right: 8px;
			}
		}
	}
}

// mat-slide css

.mat-slide-toggle-content {
	font-size: 13px;
	color: $slr-gray-2;
}
// mat-slide css end

// Global Card Styling

// mobile/desktop header css start

.header-branding {
	span {
		&.mobile-head {
			display: contents;
		}
	}
}

// mobile/desktop header css end
.slr-card.card {
	background-color: $slr-white;
	border: none;
	border-radius: 8px;
	padding: 20px;
	.card-header {
		background-color: $slr-white;
		border: none;
	}
}

.no-padding-popup {
	.mat-dialog-container {
		padding: 0;
	}
}
.mobilechatpopup {
	max-width: 80vw;
	width: 30%;
	height: 75%;
	position: static;
	margin-left: 64%;
	margin-top: -100 !important;
}

.notification-mobile-chat-popup {
	max-width: 80vw;
	width: 30%;
	height: 50%;
	position: relative;
	margin-left: 70%;
	margin-top: -12% !important;
}

// accordion ccs start
.accordion-wrap {
	.accordion {
		margin-top: 20px;
		.card {
			border: none;
			margin-bottom: 10px;
			padding: 12px;
			box-shadow: 0px 2px 6px $slr-gray-1;
			h2 {
				cursor: pointer;
				font-size: 16px;
				font-weight: 600;
			}
			&-body {
				padding-left: 0;
				padding-right: 0;
				font-size: 14px;
			}
		}
	}
}

.video-transcript {
	border: 1px solid $slr-gray-1;
	border-radius: 5px;
	h3 {
		font-size: 14px;
		font-weight: 600;
		padding: 10px;
		margin-top: 5px;
		span {
			float: right;
			font-size: 18px;
			color: $slr-gray-2;
			cursor: pointer;
		}
	}
	ul {
		list-style: none;
		padding: 10px;
		margin: 0px;
		background: $slr-gray-0;
		li {
			font-size: 13px;
			border-bottom: 1px solid $slr-gray-1;
			padding-bottom: 10px;
			margin-bottom: 10px;
			span {
				font-weight: 700;
				margin-right: 10px;
			}
		}
	}
}

// accordion ccs end

// course details feedback modal
.sendfeedback-wrap {
	padding: 0px 20px;
	.alert-primary {
		border: 1px solid $slr-blue-1;
		border-left: 7px solid $slr-blue-1;
		background-color: $slr-blue-5;
		min-height: 50px;
		font-weight: 600;
		font-size: 14px;
		padding: 12px;
		margin-bottom: 10px;
		border-radius: 5px;
		color: $slr-gray-4;
		span {
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;
			color: $slr-blue-1;
		}
		p {
			margin-bottom: 0px;
		}
	}
	.form-group {
		font-size: 13px;
		margin-bottom: 10px;
		label {
			color: $slr-blue-2;
			position: relative;
			top: 9px;
			background: $slr-white;
			left: 10px;
		}
		input {
			font-size: 13px;
			height: 45px;
			border: 1px solid $slr-blue-1;
		}
		textarea {
			font-size: 13px;
			height: 45px;
			border: 1px solid $slr-blue-1;
			display: block;
			width: 100%;
			padding: 10px;
			resize: none;
			color: $slr-gray-3;
			border-radius: 5px;
			&:focus-visible {
				outline-color: transparent !important;
			}
			&:focus {
				box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
			}
		}
	}
	.modal-footer {
		border-top: 1px solid transparent;
	}
	.submit-btn {
		height: 50px;
		width: 130px;
	}
	.cancel-btn {
		height: 50px;
		width: 130px;
	}
	h3 {
		font-size: 18px;
		color: $slr-gray-7;
		font-weight: 600;
		margin-top: 5px;
		margin-bottom: 15px;
	}
	.mat-radio-label {
		white-space: pre-wrap;
	}
	.mat-form-field-wrapper {
		margin-top: 10px !important;
	}
}
.float-left {
	float: left;
}

// course details feedback modal

// my courses live event css
.assign-wrap {
	h6 {
		font-size: 20px;
		font-weight: 600;
	}
}

// Roles assign popup
.account-detail {
	font-size: 13px;
}
.remove-user-icon {
	color: $slr-red-0;
	font-size: 15px;
	cursor: pointer;
}

// check box custom css start

.custom-checkbox {
	cursor: pointer;
	display: block;
	font-size: 14px;
	line-height: 20px;
	margin: 20px 0 20px;
	padding: 0 0 0 40px;
	position: relative;
	color: $slr-gray-3;
	span {
		&.checkbox {
			background-color: $slr-white;
			border: solid 2px $slr-gray-2;
			border-radius: 50%;
			cursor: pointer;
			display: block;
			height: 24px;
			margin: 0px;
			position: absolute;
			left: 0;
			top: 0px;
			width: 24px;
		}
	}
	input[type="checkbox"] {
		display: none;
	}
	input[type="checkbox"]:checked + span.checkbox {
		background: $slr-blue-2;
		border-color: $slr-blue-2;
		text-align: center;
	}
	input[type="checkbox"]:checked + span.checkbox:before {
		content: "\f00c";
		color: $slr-white;
		font: normal 13px/1 'FontAwesome', sans-serif;
	}
}
// check box custom css end

.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.cursor-grab {
	cursor: grab;
}
.cursor-grabbing {
	cursor: grabbing;
}
.cursor-auto {
	cursor: default !important;
}
