@import "./colors.scss";
.graphical-report {
	.card-header {
		box-shadow: 0px 3px 6px $slr-gray-5;
		background-color: $slr-white;
		h4 {
			font-weight: bold;
			color: $slr-gray-7;
		}
	}
	.user-list {
		max-height: 45vh;
		overflow-y: auto;
		color: $slr-gray-3;
		div {
			border-left: 3px solid transparent;
		}
	}
	.active-user {
		color: $slr-blue-2;
		background-color: $slr-blue-0;
		border-left-color: $slr-blue-2 !important;
	}
	.user-name {
		font-weight: bold;
		font-size: 16px;
	}
	.graph-div {
		width: 32%;
		padding: 10px;
		background-color: $slr-gray-0;
		border-radius: 20px;
	}
}
.report {
	thead {
		background-color: $slr-blue-0;
		color: $slr-blue-2;
	}
	th,
	td {
		min-width: 46px;
	}
	th {
		vertical-align: top;
	}
	tbody {
		.table-cell {
			min-height: 50px;
		}
		tr {
			&:nth-of-type(odd) {
				background-color: $slr-white;
			}
			&:nth-of-type(even) {
				background-color: $slr-gray-0;
			}
		}
		.date-value {
			white-space: nowrap;
		}
		.expanded-folder {
			color: $slr-blue-2;
		}
		.collapsed-folder {
			color: $slr-gray-2;
		}
		.user-assigned {
			color: $slr-orange;
		}
	}
	border-radius: 8px;
	border: 1px solid $slr-gray-1;
	.action-icons {
		width: 20px;
		height: 20px;
	}
}
.expand-btn {
	border-radius: 20px;
	background-color: $slr-blue-0;
	color: $slr-blue-2;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 7px;
	height: fit-content !important;
	display: inline-flex;
	align-items: center;
}
.cat-expand-btn {
	border-radius: 20px;
	background-color: $slr-white !important;
	color: $slr-blue-2;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 7px;
	height: fit-content !important;
	display: inline-flex;
	align-items: center;
}
.category-name {
	background-color: $slr-blue-8 !important;
	color: $slr-white;
}
.subcategory-name {
	background-color: $slr-blue-1 !important;
	color: $slr-white;
}
.row-span {
	vertical-align: middle;
}
.empty-cell {
	color: $slr-red-0;
}
.category-row {
	font-size: 18px;
	@extend .category-name;
}
.sub-category-row {
	font-size: 17px;
	@extend .subcategory-name;
}
.medal-icon {
	color: $slr-orange;
}
.report-card {
	background-color: $slr-white;
	color: $slr-gray-3;
	border-radius: 8px;
	padding: 10px 10px;
}
.active-report-option {
	background-color: $slr-blue-0;
	color: $slr-blue-2;
}
.indicator {
	border-radius: 5px;
	height: 22px;
	width: 22px;
}
.approved-skill-indicator {
	@extend .indicator;
	background-color: $slr-green-0;
}
.pending-skill-indicator {
	@extend .indicator;
	background-color: $slr-orange-0;
}
.pending-course-skill-indicator {
	@extend .indicator;
	background-color: $slr-red-3;
}
.location-name {
	background-color: $slr-gray-3;
	color: $slr-white;
	border-radius: 8px;
}
.approved-skill {
	background-color: $slr-green-0 !important;
	color: $slr-white;
}
.skill-status {
	border-radius: 20px;
}
.pending-course {
	background-color: $slr-orange-0 !important;
	color: $slr-white !important;
}
.passed-due-skill {
	background-color: $slr-red-5 !important;
}
.coming-due-skill {
	background-color: $slr-orange-2 !important;
}
@for $i from 1 through 100 {
	.percentage-#{$i} {
		background: linear-gradient(
			90deg,
			#ffc0cb #{$i},
			#00ffff calc(100-#{$i})
		);
	}
}
.filter-dropdown {
	.ng-placeholder {
		display: block;
	}
}
.summary-report .mat-checkbox {
	width: 100%;
}
.report-datepicker {
	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0;
	}
	.mat-form-field-outline {
		max-height: 40px;
	}
}
.completed-course {
	@extend .approved-skill;
}
.non-completed-course {
	background-color: $slr-orange !important;
	color: $slr-white !important;
}
.sticky-table {
	th:first-child,
	td:first-child {
		position: sticky;
		left: 0;
		z-index: 1;
		background-color: inherit;
	}
}
.non-present-course {
	background-color: $slr-red-0 !important;
}
.sticky-head {
	background-color: $slr-blue-0 !important;
}
.cat-row {
	font-weight: bold;
}
.view-switcher {
	span {
		font-size: 16px;
		color: $slr-gray-2;
	}
	.active-view span {
		color: $slr-blue-2;
		font-weight: 600;
	}
}
.test-link {
	text-decoration: none;
	color: $slr-blue-2;
}
.underline {
	text-decoration: underline;
}
.green-header {
	color: $slr-green-0 !important;
}
.completion-box {
	border-radius: 5px;
	color: $slr-white;
	padding: 2px 5px;
}
.audit-type {
	color: $slr-white;
	font-weight: bold;
	padding: 2px 5px;
	border-radius: 5px;
}
.part-audit {
	background-color: $slr-blue-1;
	@extend .audit-type;
}
.standard-audit {
	background-color: $slr-orange-3;
	@extend .audit-type;
}
.new-course {
	border-radius: 20px;
	background-color: $slr-red-1;
	font-size: 10px;
	font-weight: 600;
	color: black;
}
.qrm-report {
	.nav-link {
		color: black;
	}
	.nav-link.active {
		background-color: $slr-white;
		font-weight: bold;
		color: $slr-red-0;
	}
}
.user-actions .icon-btn {
	background-color: $slr-green-0;
	&:hover {
		background-color: $slr-green-3;
	}
}
.bordered-box {
	border: 1px solid;
	border-color: $slr-gray-13;
	border-top: none;
	background-color: $slr-gray-13;
}
.detail-qrm-report {
	.nav-link.active {
		background-color: $slr-gray-13;
		border-bottom: none;
	}
}
@media (max-width: 1020px) {
	.mobilereport {
		flex-direction: column;
		width: 100%;
		.report-card {
			margin-left: 0 !important;
			margin-top: 8px;
		}
	}
}
.break-name {
	word-break: break-word;
	display: inline-block;
}
.report-inactive-course {
	color: $slr-red-3;
}
.report-archived-course {
	color: $slr-red-1;
}
.sticky-report {
	@media (min-width: 1100px) {
		thead,
		th {
			position: sticky;
			top: 65px;
			z-index: 2;
		}
	}
	@media (max-width: 1100px) {
		.table-div,
		.common-table {
			overflow: auto;
			max-height: 560px;
		}

		thead {
			position: sticky;
			top: 0;
			z-index: 5;
		}
	}
}
