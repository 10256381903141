$headerHeight: 65px;
$footerHeight: 50px;
$normalBtnWidth: 200px;
$smallBtnWidth: 120px;
$actionBtnWidth: 150px;
$normalBtnFont: 16px;
$searchBoxHeight: 50px;
$miniSidebarWidth: 6%;
$miniSidebarWidthTablet: 8%;
$miniSidebarWidthMobile: 11%;
$expandedSidebarWidth: 20%;
$expandedSidebarWidthTablet: 22%;
$helpSidebarWidth: 30%;

// z-indices
$notification-z-index: 99999;
$loader-z-index: 99899;
$dialog-z-index: 99799;
$help-system-z-index: 1061