@import "./variables.scss";
@import "./colors.scss";
@import "./mixins.scss";
.mat-form-field {
	font-family: 'Source Sans Pro', sans-serif;
	&.mat-form-field-type-mat-input {
		width: 100%;
		.mat-form-field-infix {
			border-top: 0;
			input {
				margin-top: 0.6375em;
			}
		}
	}

	&.mat-form-field-should-float {
		mat-label {
			font-size: 18px;
		}
	}
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
	padding-bottom: 0;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 0.7rem 0 0.7rem 0;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
	margin-top: 1.5rem;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
	border-top: 0;
}
.mat-form-field-appearance-outline .mat-form-field-label-wrapper {
	top: -0.1rem;
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
	border-radius: 1.000001px !important;
}

// CheckBox related styles
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
	opacity: 0.03 !important;
	background-color: $slr-white !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: $slr-white;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-checkmark {
	border: 1px solid $slr-blue-2;
	border-radius: 2px;
}

.mat-checkbox-checkmark-path {
	stroke: $slr-blue-2 !important;
}
.mat-checkbox-ripple {
	display: none;
}

.mat-form-field-appearance-standard.auth {
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $slr-white;
	}
	&.mat-form-field {
		width: 100%;
	}
	.mat-form-field-required-marker {
		color: $slr-white;
	}
	.mat-form-field-ripple {
		background-color: $slr-white;
	}

	.mat-form-field-appearance-standard .mat-form-field-underline {
		background-color: $slr-white;
	}
	.mat-form-field.mat-focused .mat-form-field-ripple {
		background-color: $slr-white;
	}

	.mat-form-field-label mat-label {
		color: $slr-white;
	}

	.mat-form-field-placeholder {
		color: $slr-white;
	}

	.mat-form-field-underline {
		background-color: $slr-white;
	}

	.mat-focused .mat-form-field-placeholder {
		color: $slr-white;
	}
	input {
		color: white;
	}

	mat-icon,
	span.material-icons-outlined {
		color: white;
	}

	&.mat-form-field-invalid {
		.mat-form-field-required-marker {
			color: $slr-red-0;
		}
		.mat-form-field-ripple {
			background-color: $slr-red-0;
		}
		.mat-form-field-label mat-label {
			color: $slr-red-0;
		}
	}
}

// RadioButton Styles
.mat-radio-button.mat-accent {
	.mat-radio-container {
		.mat-radio-outer-circle {
			border-color: $slr-gray-2;
			border-width: 1px;
		}
		.mat-radio-inner-circle {
			background-color: $slr-white;
		}
		.mat-ripple {
			display: none;
		}
	}
	&.mat-radio-checked {
		.mat-radio-container {
			.mat-radio-outer-circle {
				background-color: $slr-blue-2;
				border-color: $slr-blue-2;
			}
			.mat-radio-inner-circle {
				background-image: url(../assets/icons/white-check.svg);
				background-color: $slr-blue-2;
				border-color: $slr-blue-2;
				background-position: 0px 0px;
				background-size: 19px;
				transform: scale(1);
			}
		}
	}
}
.mat-radio-group {
	.mat-radio-ripple {
		display: none;
	}
	.mat-radio-button.mat-accent.mat-radio-checked {
		.mat-radio-outer-circle {
			border-color: $slr-blue-2;
		}
		.mat-radio-inner-circle {
			background-color: $slr-blue-2;
		}
	}
}
.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-start,
.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-end,
.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-gap {
	border-width: 1px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: $slr-gray-1;
}

.mat-form-field-outline-start,
.mat-form-field-outline-gap,
.mat-form-field-outline-end {
	background-color: $slr-white;
}
.mat-form-field-label {
	top: 1.24375em !important;
}
.mat-form-field-infix input {
	margin-top: 0px !important;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
	.mat-form-field-label {
	transform: translateY(-1.14375em) scale(0.75) !important;
}
.file-input {
	.mat-form-field-infix {
		padding: 0.8em 0 0.5em 0 !important;
	}
	.mat-form-field-flex {
		min-height: 50px;
	}
	.mat-toolbar {
		input {
			margin-top: 0px !important;
		}
		.mat-primary {
			color: $slr-blue-2 !important;
			font-weight: 600;
		}
	}
}
.mat-checkbox-disabled .mat-checkbox-frame {
	background-color: $slr-gray-0 !important;
}
.mat-radio-disabled .mat-radio-outer-circle {
	background-color: $slr-gray-0 !important;
}
.customize .mat-checkbox-label {
	white-space: normal;
}

.cdk-overlay-container {
	z-index: $dialog-z-index;
}

.slr-menu {
	min-height: fit-content !important;
	.mat-menu-content {
		&:not(:empty) {
			padding: 0 !important;
		}
		.mat-menu-item {
			height: auto;
			line-height: 39px !important;
			&:not(:last-child) {
				border-bottom: 1px solid $slr-gray-1;
			}
		}
		.mat-icon {
			font-size: 1rem !important;
		}
	}
}

//Tab Group

.mat-tab-group.slr-tab-group {
	.mat-tab-label {
		height: 40px;
		padding: 0 12px;
		min-width: 120px;
		&.mat-tab-label-active {
			color: $slr-white;
			background-color: $slr-blue-1;
		}
	}
	.mat-ink-bar {
		background-color: $slr-blue-2;
	}
	.container,
	.container-fluid {
		.row {
			background-color: $slr-white;
			h5 {
				font-size: 14px;
				font-weight: 900;
			}
			h6 {
				font-size: 12px;
				font-weight: 200;
				color: $slr-gray-2;
			}
		}
	}
}

.mat-tab-nav-bar.mat-tab-header.slr-tab-group {
	.mat-tab-link-container {
		margin: 0 30px;

		.mat-tab-links {
			.mat-tab-link {
				opacity: 1;
				font-weight: bolder;
				min-width: fit-content;
				height: 40px;
				&:hover {
					color: $slr-black;
				}
				&.mat-tab-label-active {
					background-color: #e8f0f9;
					color: #0d80bc;
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}
		.mat-tab-link-bar {
			background-color: $slr-blue-2;
		}
	}
	// .mat-tab-label {
	// 	opacity: 1;
	// 	height: 40px;
	// 	padding: 0 12px;
	// 	min-width: 120px;
	// 	font-weight: bolder;
	// 	&.mat-tab-label-active {
	// 		color: $slr-blue-2;
	// 		font-weight: bolder;
	// 		background-color: $slr-blue-0;
	// 		border-top-left-radius: 8px;
	// 		border-top-right-radius: 8px;
	// 	}
	// }
}

.mat-tab-group.settings-tab-group {
	.mat-tab-header {
		.mat-tab-label-container {
			margin: 0 30px;
		}
	}
	.mat-tab-body-wrapper {
		padding: 16px 20px;
		background-color: $slr-white;
	}
	.mat-tab-label {
		opacity: 1;
		height: 40px;
		padding: 0 12px;
		min-width: 120px;
		font-weight: bolder;
		&.mat-tab-label-active {
			color: $slr-blue-2;
			font-weight: bolder;
			background-color: $slr-blue-0;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
	.container,
	.container-fluid {
		.row {
			background-color: $slr-white;
			h5 {
				font-size: 14px;
				font-weight: 900;
			}
			h6 {
				font-size: 12px;
				font-weight: 200;
				color: $slr-gray-2;
			}
		}
	}
}

.mat-tab-group.qrm-slr-tab-group {
	.mat-tab-label {
		opacity: 1;
		&.mat-tab-label-active {
			color: $slr-white;
			background-color: $slr-blue-2;
		}
	}
}

// For Directory Table
$level: (0, 1, 2, 3, 4);

@each $i in $level {
	$spacing: 20px;
	.head-level-#{$i} {
		margin-left: ($spacing * $i);
	}
	.file-level-#{$i} {
		margin-left: (($spacing * $i) + 40);
	}
}

// Mat Paginator Styles

.mat-paginator-range-actions {
	.pag-item {
		border-radius: 4px;
		box-shadow: none;
		border: 1px solid $slr-gray-2;
		margin: 0 2px;
		color: $slr-gray-2;
		width: 32px;
		height: 32px;
		&.disabled {
			color: white;
			border-color: $slr-blue-2;
			background-color: $slr-blue-2 !important;
		}
	}
}

.small-icon {
	width: 1rem !important;
	height: 1rem !important;
	font-size: 1rem;
	color: grey;
}

.no-data-row {
	background-color: white;
	padding: 15px 24px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 16px;
	border-width: 1px;
	border-color: #cccccc;
	border-style: solid;
	border-top: none;
}

.of-visible .mat-dialog-container {
	overflow: visible;
}

.skill-icon {
	font-size: 1.6rem;
	width: 1.6rem;
	height: 1.6rem;
	color: grey;
	position: relative;
	&.sub-icon {
		&::after {
			content: "\2713";
			position: absolute;
			left: -4px;
			background: $slr-green-0;
			color: $slr-white;
			padding: 4px 5px;
			border-radius: 100%;
			top: 10px;
			font-size: 10px;
		}
	}
}

.e-dlg-container.e-dlg-center-center {
	z-index: 100000 !important;
}
.e-dropdown-popup.e-lib.e-popup.e-control.e-de-toolbar-btn-first.e-caret-hide.e-popup-open {
	position: fixed;
}
.mat-error.br-red.error {
	background-color: $slr-red-0;
	color: $slr-white;
	font-size: 13px;
	padding: 3px 2px;
	border-radius: 4px;
}

main.login {
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
	mat-form-field .mat-form-field {
		&-underline {
			position: relative;
			bottom: auto;
		}
		&-subscript-wrapper {
			position: static;
		}
	}
}

.time-popup {
	display: flex;
	gap: 5px;
	max-height: 270px;
	.hours,
	.minutes,
	.am-pm {
		padding: 0 10px;
		overflow-y: auto;

		.hour,
		.minute,
		.am,
		.pm {
			padding: 5px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			cursor: pointer;
		}
	}
	.hours > .hour:hover,
	.minutes > .minute:hover,
	.am-pm > .am:hover,
	.am-pm > .pm:hover {
		border: 1px solid black;
	}
	.hours > .hour.selected,
	.minutes > .minute.selected,
	.am-pm > .am.selected,
	.am-pm > .pm.selected {
		background-color: $slr-blue-0;
	}
}

.slr-select {
	margin-top: 30px !important;
	margin-left: 30px !important;
}
.slr-time-input {
	height: 240px;
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 6px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $slr-gray-10;
		border-radius: 6px;
	}
}

.mob-modal-padding {
	.mat-dialog-container {
		padding: 8px 10px;
	}
}
