// General

$slr-white: #ffffff;
$slr-black: #000000;
$slr-black-1: #333e48;
$slr-orange: #f15929;
$slr-yellow: #ffb253;
$slr-yellow-1: #f7ff0a;
$slr-yellow-2: #f9be00;
$slr-yellow-3: #ffc714;
// Gray Shades

$slr-gray-list: (
	"0": #f8f8f8,
	"1": #dddddd,
	"2": #999999,
	"3": #666666,
	"4": #3a3a3a,
	"5": #00000029,
	"6": #001428,
	"7": #272727,
	"8": #eeeeee,
	"9": rgba(0, 0, 0, 0.25),
	"10": #cccccc,
	"11": #f2f2f2,
	"12": #575757,
	"13": rgb(222, 226, 230),
	"14": #f6f6f6,
	"15": #e9ecef,
);

$slr-gray-0: map-get($slr-gray-list, "0");
$slr-gray-1: map-get($slr-gray-list, "1");
$slr-gray-2: map-get($slr-gray-list, "2");
$slr-gray-3: map-get($slr-gray-list, "3");
$slr-gray-4: map-get($slr-gray-list, "4");
$slr-gray-5: map-get($slr-gray-list, "5");
$slr-gray-6: map-get($slr-gray-list, "6");
$slr-gray-7: map-get($slr-gray-list, "7");
$slr-gray-8: map-get($slr-gray-list, "8");
$slr-gray-9: map-get($slr-gray-list, "9");
$slr-gray-10: map-get($slr-gray-list, "10");
$slr-gray-11: map-get($slr-gray-list, "11");
$slr-gray-12: map-get($slr-gray-list, "12");
$slr-gray-13: map-get($slr-gray-list, "13");
$slr-gray-14: map-get($slr-gray-list, "14");
$slr-gray-15: map-get($slr-gray-list, "15");

// Red Shades

$slr-red-list: (
	"0": #f7786a,
	"1": #fa7500,
	"2": #f6922020,
	"3": #ab0c30,
	"4": #f7786a1c,
	"5": #ffe4e1,
	"6": #ff0000,
);

$slr-red-0: map-get($slr-red-list, "0");
$slr-red-1: map-get($slr-red-list, "1");
$slr-red-2: map-get($slr-red-list, "2");
$slr-red-3: map-get($slr-red-list, "3");
$slr-red-4: map-get($slr-red-list, "4");
$slr-red-5: map-get($slr-red-list, "5");
$slr-red-6: map-get($slr-red-list, "6");

// Green Shades

$slr-green-list: (
	"0": #1cd17f,
	"1": #1cd17f1c,
	"2": #dafded,
	"3": #5cb85c,
	"4": #419042ed,
	"5": #4d9b71,
	"6": #5bc47c,
	"7": #5bc47c20,
);

$slr-green-0: map-get($slr-green-list, "0");
$slr-green-1: map-get($slr-green-list, "1");
$slr-green-2: map-get($slr-green-list, "2");
$slr-green-3: map-get($slr-green-list, "3");
$slr-green-4: map-get($slr-green-list, "4");
$slr-green-5: map-get($slr-green-list, "5");
$slr-green-6: map-get($slr-green-list, "6");
$slr-green-7: map-get($slr-green-list, "7");

// Blue Shades

$slr-blue-list: (
	"0": #e8f0f9,
	"1": #36a3dc,
	"2": #0d80bc,
	"3": #03337f,
	"4": #36a3dc19,
	"5": #36a3dc1a,
	"6": #7676ff,
	"7": #56a2d7,
	"8": #2f68b7,
	"9": #1567bd4d,
);

$slr-blue-0: map-get($slr-blue-list, "0");
$slr-blue-1: map-get($slr-blue-list, "1");
$slr-blue-2: map-get($slr-blue-list, "2");
$slr-blue-3: map-get($slr-blue-list, "3");
$slr-blue-4: map-get($slr-blue-list, "4");
$slr-blue-5: map-get($slr-blue-list, "5");
$slr-blue-6: map-get($slr-blue-list, "6");
$slr-blue-7: map-get($slr-blue-list, "7");
$slr-blue-8: map-get($slr-blue-list, "8");
$slr-blue-9: map-get($slr-blue-list, "9");

// Orange Shades

$slr-orange-list: (
	"0": #ffb253,
	"1": #ffb25319,
	"2": #f3f8c8,
	"3": #ffc680,
	"4": #ffddb3,
);

$slr-orange-0: map-get($slr-orange-list, "0");
$slr-orange-1: map-get($slr-orange-list, "1");
$slr-orange-2: map-get($slr-orange-list, "2");
$slr-orange-3: map-get($slr-orange-list, "3");
$slr-orange-4: map-get($slr-orange-list, "4");

// color classes

.color-white {
	color: $slr-white;
}

@each $color, $i in $slr-blue-list {
	.bg-blue-#{$color} {
		background-color: $i;
	}
	.color-blue-#{$color} {
		color: $i;
	}
}
@each $color, $i in $slr-green-list {
	.bg-green-#{$color} {
		background-color: $i;
	}
	.color-green-#{$color} {
		color: $i;
	}
}
@each $color, $i in $slr-red-list {
	.bg-red-#{$color} {
		background-color: $i;
	}
	.color-red-#{$color} {
		color: $i;
	}
}
@each $color, $i in $slr-gray-list {
	.bg-gray-#{$color} {
		background-color: $i;
	}
	.color-gray-#{$color} {
		color: $i;
	}
}
