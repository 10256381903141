// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$SurgeLearning-WebApp-primary: mat.define-palette(mat.$indigo-palette);
$SurgeLearning-WebApp-accent: mat.define-palette(
	mat.$pink-palette,
	A200,
	A100,
	A400
);
$SurgeLearning-WebApp-font: mat.define-typography-config(
	$font-family: "Source Sans Pro",
);

// The warn palette is optional (defaults to red).
$SurgeLearning-WebApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$SurgeLearning-WebApp-theme: mat.define-light-theme(
	(
		color: (
			primary: $SurgeLearning-WebApp-primary,
			accent: $SurgeLearning-WebApp-accent,
			warn: $SurgeLearning-WebApp-warn,
		),
		typography: $SurgeLearning-WebApp-font,
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($SurgeLearning-WebApp-theme);

@import "./scss/variables.scss";
@import "./scss/mixins.scss";
@import "./scss/colors.scss";
@import "./scss/popover.scss";
@import "./scss/table.scss";
@import "./scss/golbal.scss";
@import "./scss/responsive.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./scss/material-styles.scss";
@import "./scss/reports.scss";
@import "./scss/calendar.scss";
@import "./scss//dropdown.scss";

//SyncFusion

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-documenteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-spreadsheet/styles/material.css";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: "Source Sans Pro", sans-serif;
}
body.modal-open {
	overflow: hidden;
}
.form-control {
	margin-top: 3px;
	border-color: $slr-gray-1;
}
.form-control::placeholder {
	color: $slr-gray-2 !important;
}
.padding-wrapper {
	padding: 0 30px;
	&.head-section {
		border-bottom: 1px solid $slr-gray-10;
	}
	&.white-bg {
		background-color: $slr-white;
	}
}
ng-select.gray-bg .ng-select-container {
	background-color: $slr-gray-8;
	border-color: $slr-gray-10;
	height: 50px !important;
	.ng-value {
		font-size: 16px;
		font-weight: 900;
	}
}
// label styling
label {
	color: $slr-gray-7;
	font-size: 16px;
	font-weight: bold;
	i {
		vertical-align: middle;
		font-size: 16px !important;
		color: $slr-gray-2;
	}
}
.boldest {
	font-weight: 900;
}
.required::after {
	content: "*";
	color: red;
}
.save-btn {
	@include btn-basic($normalBtnFont, $actionBtnWidth);
	border: 1px $slr-blue-2 solid;
	font-weight: bold;
	color: $slr-blue-2 !important;
	padding: 0px 20px;
	&:hover {
		background-color: $slr-blue-0 !important;
	}

	&.policy {
		border-color: $slr-green-6;
		color: $slr-black !important;
		&:hover {
			background-color: $slr-green-2 !important;
		}
	}
}
.submit-btn {
	@include btn-basic($normalBtnFont, $actionBtnWidth);
	font-weight: bold;
	background-color: $slr-blue-2;
	color: $slr-white;
	&:hover {
		color: $slr-white;
		background-color: $slr-blue-3;
	}
	&.policy {
		background-color: $slr-green-6;
		color: $slr-black;
		// &:hover {
		// 	background-color: $slr-green-2 !important;
		// }
	}
}

.section-btn {
	background-color: $slr-yellow-2;
	color: $slr-black-1;
	height: 32px !important;
	padding: 8px 10px;
	font-size: 11px !important;
	font-weight: bold !important;
	text-transform: uppercase;
	&:hover {
		color: $slr-black-1;
		background-color: $slr-yellow-3;
	}
}

.secondary-btn {
	@include btn-basic($normalBtnFont, $actionBtnWidth);
	font-weight: bold;
	background-color: $slr-white;
	color: $slr-blue-2;
	border-color: $slr-blue-2;
	&:hover {
		color: $slr-blue-2;
	}
}
/* ----------------- Global Pagination Style Start ---------------- */

.pagination .page-item:not(:first-child) .page-link {
	margin-left: 4px;
}

.pagination .page-item.disabled:not(:first-child):not(:last-child) .page-link {
	border: none;
	margin: 0px;
	background-color: $slr-gray-0;
}

.pagination .page-item.active .page-link {
	color: $slr-white;
	background-color: $slr-blue-2;
}

.pagination
	.page-item.disabled:not(:first-child):not(:last-child)
	+ .page-item
	.page-link {
	margin: 0px;
}

.pagination {
	.page-item .page-link {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		color: $slr-gray-2;
		border: 1px solid $slr-gray-1;
		border-radius: 5px;
		box-shadow: none;
	}
	margin-bottom: 0;
}

.pagination .page-item .page-link .sr-only {
	display: none;
}

.pagination .page-item .page-link .material-icons-outlined {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	padding: 4px 0px;
}

.pagination .page-item:first-child .page-link {
	border-radius: 5px;
	border: 1px solid $slr-blue-2;
	color: $slr-blue-2;
	&:hover,
	&:focus {
		color: $slr-white;
		background-color: $slr-blue-2;
	}
}

.pagination .page-item:last-child .page-link {
	border: 1px solid $slr-blue-2;
	color: $slr-blue-2;
	border-radius: 5px;
	&:hover,
	&:focus {
		color: $slr-white;
		background-color: $slr-blue-2;
	}
}

/* ----------------- Global Pagination Style End ---------------- */

.cancel-btn {
	@include btn-basic($normalBtnFont, $actionBtnWidth);
	border: 1px #cccc solid;
	font-weight: bold;
	color: $slr-gray-2 !important;
	&:hover {
		color: $slr-gray-3 !important;
		border: 1px $slr-gray-3 solid;
	}
}
.skip-btn {
	border-color: $slr-blue-2;
	color: $slr-blue-2 !important;
}
.search-box {
	.input-group {
		height: $searchBoxHeight;
		border: 1px solid $slr-gray-10;
		border-radius: 8px 8px 0 0;
		border-bottom-width: 0;
		background-color: $slr-white;
		.input-group-append {
			border-radius: 8px !important;

			// border-top-left-radius: 0 !important;
			// border-bottom-left-radius: 0 !important;
		}
		&:focus-within {
			border-color: $slr-gray-3;
			.input-group-append {
				background-color: $slr-blue-0;
			}
		}
		.form-control {
			border-radius: 8px;
			border: none;
			box-shadow: none;
			margin: 0;
			&:focus::placeholder {
				color: transparent;
			}
		}
		.input-group-append {
			width: 48px;
			height: 48px;
			margin-left: 0 !important;
			i {
				color: $slr-blue-2;
				font-size: 24px;
			}
		}
	}

	&.policy {
		.input-group-append {
			i {
				color: $slr-black;
			}
			background-color: $slr-green-7;
		}
		&:focus-within {
			.input-group-append {
				background-color: $slr-green-6;
			}
		}
	}
	&.qrm {
		.input-group-append {
			i {
				color: $slr-black;
			}
			background-color: $slr-red-2;
		}
		&:focus-within {
			.input-group-append {
				background-color: $slr-red-1;
			}
		}
	}
}
.ng-arrow-wrapper .ng-arrow {
	display: none !important;
}
// ------------------------------ Global ng-select Style for header Start -------------------------
.header {
	.ng-select .ng-select-container {
		width: auto;
		height: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		background-color: $slr-gray-0;
		color: $slr-blue-2;
		text-align: left;
		border-radius: 24px;
		font-size: 14px;
		&:hover {
			box-shadow: none;
		}
	}
	.ng-dropdown-panel {
		width: max-content;
	}
	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: $slr-blue-2;
	}
	.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
		border-radius: 24px;
	}
	.ng-select.ng-select-opened > .ng-select-container {
		background-color: $slr-gray-0;
	}
	.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
		box-shadow: none;
	}
}
// ------------------------------ Global ng-select Style End ---------------------------
.icon-btn {
	font-size: 14px;
	font-weight: bold;
	background-color: $slr-blue-2;
	color: $slr-white;
	align-items: center;
	text-transform: uppercase;
	i {
		font-size: 18px;
		vertical-align: text-bottom;
	}
	&:hover {
		background-color: $slr-blue-3;
		color: $slr-white;
		i {
			transform: rotate(90deg);
			transition-duration: 0.2s;
		}
	}
}
.icon-btn-red {
	font-size: 14px;
	font-weight: bold;
	background-color: $slr-orange;
	color: $slr-white;
	align-items: center;
	text-transform: uppercase;
	i {
		font-size: 18px;
		vertical-align: text-bottom;
	}
	&:hover {
		background-color: $slr-red-3;
		color: $slr-white;
		i {
			transform: rotate(90deg);
			transition-duration: 0.2s;
		}
	}
}
.icon-btn-white {
	font-size: 14px;
	font-weight: bold;
	background-color: $slr-white;
	color: $slr-blue-3;
	align-items: center;
	text-transform: uppercase;
	border: 1px solid $slr-blue-3;
	i {
		font-size: 18px;
	}
	&:hover {
		background-color: $slr-blue-3;
		color: $slr-white;
		i {
			transform: rotate(90deg);
			transition-duration: 0.2s;
		}
	}
}

.ng-arrow-wrapper::after {
	font-family: 'Material Icons', sans-serif;
	content: "expand_more";
	color: $slr-blue-2;
	font-size: 16px;
}
.ng-select-opened .ng-arrow-wrapper::after {
	content: "expand_less" !important;
}
.users-input .ng-arrow-wrapper::after {
	font-family: 'Material Icons', sans-serif;
	content: "person_add_alt";
	color: $slr-blue-2;
	font-size: 16px;
}
.users-input.ng-select-opened .ng-arrow-wrapper::after {
	content: "person_add_alt" !important;
}
.ng-dropdown-panel-items {
	font-size: 16px;
	max-height: 260px !important;
}
.toggle-tabs {
	border-radius: 8px 8px 0px 0px;
	li {
		color: $slr-gray-4;
		font-size: 16px;
		font-weight: 900;
		a,
		a:hover {
			color: $slr-gray-4;
		}
		.active {
			color: $slr-blue-2 !important;
			background-color: $slr-blue-0 !important;
			border-bottom: 3px solid $slr-blue-2;
			border-radius: 8px 8px 0px 0px;
		}
	}

	&.policy {
		li {
			.active {
				color: $slr-black !important;
				background-color: $slr-green-7 !important;
				border-bottom: 3px solid $slr-green-6;
			}
		}
	}
	&.qrm {
		li {
			.active {
				color: $slr-black !important;
				background-color: $slr-red-2 !important;
				border-bottom: 3px solid $slr-red-1;
			}
		}
	}
}
.action-btn {
	border: 1px solid $slr-blue-9;
	background-color: $slr-white;
	border-radius: 4px;
	color: $slr-gray-3;
	font-size: 16px;
	width: max-content;
	font-weight: 600;
	&:hover {
		border-color: $slr-blue-2;
		color: $slr-blue-2;
		background-color: $slr-blue-0;
	}
	&:disabled {
		background-color: $slr-gray-14;
		border-color: $slr-gray-10;
	}
}
// Limit dropdown styling
.limit-dropdown {
	.ng-select {
		width: 80px;
		color: $slr-blue-2 !important;
		.ng-value-label {
			color: $slr-blue-2;
			font-weight: bold;
		}
	}
}
.popover {
	border: none;
}
.popover-body,
.popover-header {
	background-color: $slr-gray-4;
	color: $slr-white;
}
.resource-centre-actions {
	margin-top: 15px;
	.ng-select {
		font-size: 16px;
	}
}
.limit-dropdown {
	.ng-select-container {
		min-height: 34px;
		height: 34px !important;
	}
}
.btn:disabled {
	opacity: 0.4;
}

// ------------------------------- Checkbox Style Starts ---------------------
.slr-checkbox.form-check-input:not(:checked),
.slr-checkbox.form-check-input:checked {
	content: "";
	width: 16px;
	height: 16px;
	background: $slr-white;
	&:focus {
		box-shadow: none;
	}
}
.slr-checkbox.form-check-input:checked[type="checkbox"] {
	background-image: url(./assets/icons/check.svg);
	border-color: $slr-blue-2;
	background-position: center;
	background-size: 14px;
	background-repeat: no-repeat;
	&:focus {
		box-shadow: none;
	}
}
.slr-checkbox.form-check-input:focus {
	border-color: $slr-gray-9;
}

.slr-switch.form-switch .form-check-input {
	&:checked {
		background-image: url(./assets/icons/switch_checked.svg);
		background-color: $slr-white;
		border-color: $slr-blue-2;
		background-size: contain;
	}
	&:not(:checked) {
		border-color: rgba(0, 0, 0, 0.25);
		background-image: url(./assets/icons/switch_unchecked.svg);
	}
	&:focus {
		box-shadow: none;
	}
}
// ------------------------------- Checkbox Style Ends ---------------------

// ------------------------------- Radio button style ----------------------
.slr-radio {
	.form-check-input {
		height: 20px;
		width: 20px;
	}
	label {
		vertical-align: middle;
		color: $slr-gray-3;
		font-weight: 400;
		padding-top: 2px;
	}
	:checked[type="radio"] {
		background-image: url(./assets/icons/white-check.svg);
		background-color: #0D80BC;
		border-color: #0D80BC;
		background-size: 14px;
		background-position: center;
		box-shadow: none;
		+ label {
			font-weight: 500;
			color: $slr-gray-7;
		}
	}
}

.slr-check-radio {
	.form-check-input {
		height: 20px;
		width: 20px;
		border-radius: 4px;
	}
	label {
		vertical-align: middle;
		color: $slr-gray-3;
		font-weight: 400;
		padding-top: 2px;
	}
	:checked[type="radio"] {
		background-image: url(./assets/icons/white-check.svg);
		background-color: #0D80BC;
		border-color: #0D80BC;
		background-size: 14px;
		background-position: center;
		box-shadow: none;
		+ label {
			font-weight: 500;
			color: $slr-gray-7;
		}
	}
}
// ----------------------------- radio button style ends -----------------

.year-dropdown.ng-select .ng-select-container {
	height: 22px !important;
	min-height: 22px !important;
	width: 60px !important;
	font-size: 10px;
	&:hover {
		box-shadow: none;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		padding: 1px 10px !important;
	}
}
.year-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 10px;
}
.lp-selector.ng-select .ng-select-container {
	// height: 30px !important;
	// min-height: 30px !important;
	font-size: 14px;
	font-weight: bold;
	&:hover {
		box-shadow: none;
	}
}
.lp-selector .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 14px;
	font-weight: bold;
}
.banner {
	border-radius: 6px;
	font-size: 14px;
	color: $slr-gray-4;
	min-height: 60px;
	font-weight: bold;
}
.warning-banner {
	border: 1px solid $slr-red-1;
	border-left: 7px solid $slr-red-1;
	background-color: $slr-red-2;
	.warning-icon {
		color: $slr-red-1;
	}
}
.info-banner {
	border: 1px solid $slr-blue-1;
	border-left: 7px solid $slr-blue-1;
	background-color: $slr-blue-4;
	.warning-icon {
		color: $slr-blue-1;
	}
}
.course-list {
	background-color: $slr-gray-0;
	max-height: 200px;
	overflow-y: auto;
	color: $slr-gray-6;
	font-size: 16px;
	.close-btn {
		color: $slr-red-0;
	}
}
.course-actions {
	::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}

	::-webkit-scrollbar {
		width: 8px;
		height: 6px;
		background-color: #f5f5f5;
	}

	::-webkit-scrollbar-thumb {
		background-color: $slr-gray-10;
		border-radius: 6px;
	}
}
.custom-scrollbar {
	@extend .course-actions;
	& .track-white {
		::-webkit-scrollbar-track {
			background-color: $slr-white;
		}
	}
}
.courses-header {
	width: 100%;
	background-color: $slr-blue-0;
	color: $slr-blue-2;
	font-size: 14px;
	font-weight: bold;
}
.ng-dropdown-panel {
	z-index: 800 !important;
}

// header css start
.content-header {
	font-weight: 700;
	color: $slr-gray-7;
	font-size: 36px;
}
.gray-header {
	@extend .content-header;
	color: $slr-gray-3;
}
.add-btn {
	border-radius: 6px;
	background-color: $slr-blue-0;
	color: $slr-blue-2;
	font-weight: bold;
	text-transform: uppercase;
	padding: 5px 15px;
	&:hover {
		color: $slr-blue-2;
	}
}
.btn {
	font-size: 16px;
	width: fit-content;
	height: 50px;
	span {
		&.align-icons {
			font-size: 18px;
			margin-right: 5px;
			vertical-align: sub;
		}
	}
}
.form-check-label {
	font-weight: 400;
}

// pagination css start
.small-label {
	@extend .items-label;
	font-weight: normal;
}
.items-label {
	color: $slr-gray-2;
	font-size: 14px;
	font-weight: bold;
}

.rwd-media {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 40.25%; /* 16:9 */
	margin-top: 5%;
}

.rwd-media {
	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.float-right {
	float: right;
}

.button-wrap {
	// margin-top: 1.4em;
	.view-pdf-btn {
		background: $slr-gray-0;
		text-align: center;
		border-radius: 5px;
		text-transform: uppercase;
		padding: 7px;
		font-weight: 600;
		font-size: 12px;
		display: inline-flex;
		margin-right: 15px;
		a {
			min-width: 100px;
			text-align: center;
			color: $slr-gray-4;
			text-decoration: none;
			span {
				top: 4px;
				position: relative;
				font-size: 18px;
				margin-right: 5px;
			}
		}
	}
	.course-btn {
		// float: right;
		background: transparent;
		text-align: center;
		border-radius: 5px;
		text-transform: uppercase;
		padding: 9px;
		font-weight: 600;
		font-size: 16px;
		border: 1px solid $slr-green-0;
		color: $slr-green-0;
		cursor: pointer;
		span {
			font-size: 24px;
			top: 6px;
			position: relative;
			margin-right: 5px;
		}
		&.completed {
			background-color: $slr-green-0;
			color: $slr-white;
		}
		&:hover {
			background-color: $slr-green-0;
			color: $slr-white;
		}
		&.disable {
			border: 1px solid $slr-gray-2 !important;
			color: $slr-gray-2 !important;
			pointer-events: none;
			&:hover {
				background-color: transparent;
				color: transparent;
			}
		}
	}
}

.see-more-link {
	color: $slr-blue-1;
	font-size: 13px;
	cursor: pointer;
	text-decoration: underline;
}
.error-control,
.error-control .ng-select-container {
	border-color: $slr-red-0;
}
.danger-text {
	color: $slr-red-0;
	font-size: 12px;
}
.content-area {
	min-height: 75%;
}
.ngb-dp-header {
	.custom-select {
		border: 1px solid;
	}
	.btn-link {
		color: $slr-blue-2;
	}
}
.ngb-dp-day {
	.text-white {
		background-color: $slr-blue-2 !important;
	}
}
.exempt-year-dropdown.ng-select .ng-select-container {
	height: 36px !important;
	min-height: 30px !important;
	width: 80px !important;
	font-size: 12px;
	&:hover {
		box-shadow: none;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		padding: 1px 10px !important;
	}
}
.exempt-year-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 12px;
}
.ngb-toasts {
	z-index: $notification-z-index !important;
}
.toast {
	font-size: 16px !important;
	border-left: 8px solid $slr-green-0 !important;
	.title {
		font-weight: bold;
	}
	i {
		font-size: 24px;
	}
	.btn {
		box-shadow: none;
		height: fit-content;
		color: $slr-gray-10;
	}
	.toast-body {
		padding-top: 7px;
		padding-bottom: 7px;
		color: $slr-gray-4;
	}
}
.toast.bg-success {
	background-color: $slr-white !important;
	border: 1px solid $slr-green-0 !important;
	border-radius: 6px !important;
	i {
		color: $slr-green-0;
	}
	.title {
		color: $slr-green-0;
	}
}
.toast.bg-danger {
	background-color: $slr-white !important;
	border: 1px solid $slr-red-0 !important;
	border-radius: 6px !important;
	i {
		color: $slr-red-0;
	}
	.title {
		color: $slr-red-0;
	}
}
.custom-dropdown {
	.ng-select.ng-select-single .ng-select-container {
		height: 38px;
	}
}
.label-outlined.form-group {
	position: relative;
	label {
		position: absolute;
		bottom: 7px;
		left: 9px;
		color: $slr-gray-2;
		font-size: 14px;
		font-weight: 400;
		pointer-events: none;
	}
	input,
	textarea {
		&::placeholder {
			color: transparent !important;
		}
		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			+ label {
				transform: translateY(-22px);
				transition: 150ms;
				width: fit-content;
				padding: 0px 7px;
				font-size: 12px;
				background-color: $slr-white;
				color: $slr-blue-2;
			}
			+ .mandatory {
				@extend .required;
			}
		}
	}
	textarea {
		+ label {
			top: 7px;
			bottom: auto;
		}
		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			+ label {
				transform: translateY(-16px);
			}
		}
	}
	.ng-select.ng-select-focused {
		.ng-select-container {
			border-color: $slr-blue-2;
		}
	}
	.ng-select.ng-select-focused,
	.ng-select.custom-dropdown {
		.ng-select-container {
			z-index: 1;
		}
		+ .label {
			transform: scale(0.75) translateY(-22px);
			transition: 150ms;
			width: fit-content;
			padding: 0px 7px;
			left: -4px;
			font-size: 16px;
			background-color: $slr-white;
			color: $slr-blue-2;
			z-index: 2;
		}
		+ .mandatory {
			@extend .required;
		}
	}
}

.create-course-form {
	.form-group {
		position: relative;
	}
	.label {
		position: absolute;
		bottom: 7px;
		left: 9px;
		color: $slr-gray-2;
		font-size: 14px;
		font-weight: 400;
		pointer-events: none;
	}
	input {
		&::placeholder {
			color: transparent !important;
		}
		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			+ .label {
				transform: scale(0.75) translateY(-25px);
				transition: 150ms;
				width: fit-content;
				padding: 0px 7px;
				left: -5px;
				font-size: 16px;
				background-color: $slr-white;
				color: $slr-blue-2;
			}
			+ .mandatory {
				@extend .required;
			}
		}
	}
	.ng-select.ng-select-focused {
		.ng-select-container {
			border-color: $slr-blue-2;
		}
	}
	.ng-select.ng-select-focused,
	.ng-select.custom-dropdown {
		.ng-select-container {
			z-index: 1;
		}
		+ .label {
			transform: scale(0.75) translateY(-22px);
			transition: 150ms;
			width: fit-content;
			padding: 0px 7px;
			left: -4px;
			font-size: 16px;
			background-color: $slr-white;
			color: $slr-blue-2;
			z-index: 2;
		}
		+ .mandatory {
			@extend .required;
		}
	}
}

html,
body {
	height: 100%;
}
.create-new {
	color: $slr-blue-2;
	font-size: 14px;
	cursor: pointer;
	font-weight: bold;
	width: fit-content;
}
.mat-checkbox {
	width: fit-content;
	font-family: "Source Sans Pro", sans-serif;
	.mat-checkbox-label {
		color: $slr-gray-3;
		font-weight: normal;
		margin-left: 3px;
		font-size: 14px;
	}
	&.mat-checkbox-checked .mat-checkbox-label {
		color: $slr-gray-7;
		font-weight: 500;
	}
	.mat-checkbox-frame {
		border-width: 1px !important;
		border-color: $slr-gray-10;
		background-color: $slr-white;
	}
}
.ql-editor {
	background-color: $slr-white;
}

.dashboard {
	.card {
		border: 0px;
		border-radius: 0px;
		background-color: red;
		.card-header {
			border-bottom: 0px;
			padding: 0px;
			background-color: transparent;
			.btn {
				font-size: 14px;
				width: 100%;
				height: 40px;
				text-align: start;
			}
			.btn-link {
				text-decoration: none;
				color: $slr-black;
			}
		}
	}
}
.mng-grp-con {
	.card {
		border: 0px;
		border-radius: 0px;
		margin-top: 1px;
		background-color: $slr-gray-0;
		.card-header {
			border-bottom: 0px;
			padding: 0px;
			.btn {
				font-size: 14px;
				width: 100%;
				height: 40px;
				text-align: start;
			}
			.btn-link {
				text-decoration: none;
				color: $slr-black;
			}
			.btn-link:hover {
				text-decoration: none;
				color: none;
			}
		}
	}
}
.drag-icon {
	color: $slr-gray-2;
}
.mat-radio-label {
	font-size: 14px;
	font-weight: 300;
}
.uploads .ql-toolbar {
	background-color: $slr-gray-1;
}
.desc-pop-up .btn {
	font-size: 24px !important;
}
.small-card .card-header {
	padding: 0;
	.accordion-button {
		background-color: $slr-white;
		font-weight: 600;
		&:focus {
			box-shadow: none !important;
		}
		&::after {
			display: none;
		}
	}
}
.rc-uploads {
	.ng-value,
	.ng-clear-wrapper {
		display: none;
	}
}
.customize {
	text-decoration: underline;
}
.bulk-add {
	.cancel-btn {
		background-color: $slr-white;
	}
}
.modal-xl {
	min-height: 90%;
	.modal-content {
		height: 100%;
	}
}
.zendesk-article {
	max-height: 80%;
	font-size: 14px;
	overflow: auto;
	img {
		max-width: 100%;
	}
	iframe {
		width: 100%;
		height: 100%;
	}
}
.resources .expand-btn {
	border-color: $slr-blue-2;
}

.ellipsis-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.report-icon-btn {
	@extend .icon-btn;
	&:hover {
		i {
			transform: rotate(180deg);
			transition-duration: 0.4s;
		}
	}
}
.test-btn {
	background-color: $slr-green-4;
	&:hover {
		background-color: $slr-green-5;
	}
}
.mobilereport {
	overflow-x: auto;
	max-width: 100%;
}
.yt-player {
	background-color: red;
	iframe {
		width: 100%;
		height: 600px;
	}
}
.disable-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
a {
	text-decoration: none;
	&.color-white {
		color: $slr-white;
	}
	&.color-black {
		color: $slr-black;
	}
	&:hover {
		text-decoration: underline;
	}
}
.vr {
	width: 0 !important;
	min-height: 1em;
	border-left: 1px solid $slr-black;
}
.redirection-link {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
}
.warning-icon.large {
	font-size: 100px;
	color: orange;
}
.mat-tooltip {
	font-size: 12px !important;
}

input::-ms-reveal {
	display: none;
}
.overflow-modal {
	max-height: 60vh;
	overflow-y: auto;
}
.cursor-default {
	cursor: default;
}
.ng-dropdown-panel {
	z-index: calc($dialog-z-index + 1) !important;
}