@import "./colors.scss";

// Custom table styling
.table-header {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: $slr-gray-1;
  color: $slr-gray-6;
  font-size: 18px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .table-header-groups {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sort-icons {
      margin-left: 10px;
      font-size: 18px;
      color: $slr-gray-2;
    }
  }
  &.lms {
      background-color: $slr-blue-2;
      color: $slr-white;
      .sort-icons {
        margin-left: 10px;
        font-size: 16px;
        color: $slr-white;
      }
  }
}
.categories-accordion {
  .card {
    margin-bottom: 10px;
    border: none;
    .card-header {
      padding: 8px 0px;
      background-color: $slr-white;
      border: none;
      box-shadow: 0px 2px 6px $slr-gray-5;
      button.btn.btn-link {
        padding: 0;
        font-size: 16px;
        width: 100%;
        padding-left: 24px;
        border: none;
        text-decoration: none;
        color: $slr-black;
        font-weight: bold;
        &:focus {
          box-shadow: none;
        }
        .panelTitle {
          display: flex;
          align-items: center;
          justify-content: start;
          padding: 10px 0;
          .folder-icons {
            margin-right: 10px;
            font-size: 16px;
          }
          .folder-icons::after {
            font-family: 'Material Icons', sans-serif;
            content: "folder_open";
            color: $slr-blue-2;
            font-size: 16px;
          }
        }
        &.collapsed .folder-icons::after {
          font-family: 'Material Icons', sans-serif;
          content: "folder";
          color: $slr-blue-2;
          font-size: 16px;
        }
      }
    }
    .card-body {
      font-size: 16px;
      padding: 0px;
      width: 100%;
      border: none;
      box-shadow: 0px 2px 6px $slr-gray-5;
      .courses-link {
        cursor: pointer;
        padding: 0px;
        margin: 0px;
        &:nth-child(2n + 1) {
          background-color: $slr-gray-0;
        }
        &:hover {
          background-color: $slr-blue-0;
        }
        .course-details {
          border: none;
          display: flex;
          align-items: center;
          padding: 10px;
          .course-duration {
            padding-left: 4px;
          }
          .course-checkboxes {
            margin-left: 40px;
            margin-right: 10px;
          }
        }
      }
    }
    .sub-categories-accordion {
      .card-body {
        box-shadow: none;
      }
      .card {
        margin-bottom: 0;
        border-radius: 0;
      }
    }
  }
}
