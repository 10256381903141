@import "./colors.scss";
// calendar css
.calendar-container {
	margin-top: 20px;
	.calendar-box {
		background-color: $slr-white;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0px 2px 6px $slr-gray-1;
		margin-bottom: 20px;
	}
	.fc-view-harness {
		height: 75vh !important;
	}
	.fc {
		.fc-col-header-cell-cushion {
			color: $slr-black;
			text-decoration: none;
			font-size: 14px;
		}
		.fc-daygrid-day-top {
			a {
				color: $slr-black;
				text-decoration: none;
				font-size: 14px;
				font-weight: 600;
			}
		}
		.fc-button-primary {
			background-color: $slr-blue-2;
			text-transform: capitalize;
		}
		.fc-button {
			padding: 0.4em 1.25em;
		}
		.fc-daygrid-day {
			&.fc-day-today {
				background-color: $slr-gray-0;
			}
		}
	}
	.events-box {
		background-color: $slr-white;
		border-radius: 5px;
		box-shadow: 0px 2px 6px $slr-gray-1;
		height: 100vh;
		h2 {
			font-size: 16px;
			font-weight: 600;
			margin-left: 20px;
			padding-top: 15px;
			margin-bottom: 15px;
			display: inline-block;
			margin-right: 45px;
			span {
				color: $slr-blue-1;
				vertical-align: bottom;
				margin-right: 10px;
				cursor: pointer;
			}
		}
		.carousel-next {
			display: contents;
			font-size: 14px;
			color: $slr-blue-2;
			font-weight: 600;
			span {
				vertical-align: middle;
				cursor: pointer;
				font-size: 18px;
				color: $slr-black;
				background-color: $slr-gray-0;
				border-radius: 5px;
			}
		}
		.accordion-wrap {
			.accordion {
				margin-top: 10px;
				.card {
					margin-bottom: 10px;
					padding: 12px;
					box-shadow: none;
					cursor: pointer;
					border-radius: inherit;
					h3 {
						font-size: 14px;
						font-weight: 600;
						cursor: pointer;
						margin-left: 10px;
						background: url(../assets/icons/down-arrow.svg)
							no-repeat calc(100% - 10px) center;
						span {
							&.text-blue {
								border-radius: 50px;
								background-color: $slr-blue-1;
								padding: 4px 6px;
								margin-left: 8px;
								font-size: 12px;
								color: $slr-white;
							}
							&.text-green {
								border-radius: 50px;
								background-color: $slr-green-0;
								padding: 4px 6px;
								color: $slr-white;
								margin-left: 8px;
								font-size: 12px;
							}
							&.text-orange {
								border-radius: 50px;
								background-color: $slr-red-0;
								padding: 4px 6px;
								margin-left: 8px;
								font-size: 12px;
							}
						}
						&.collapsed {
							background-image: url(../assets/icons/up-arrow.svg);
						}
					}
					&.blue-head {
						background-color: $slr-blue-0;
						color: $slr-blue-2;
						border-left: 3px solid $slr-blue-1;
					}
					&.green-head {
						background-color: $slr-green-2;
						color: $slr-green-0;
						border-left: 3px solid $slr-green-0;
					}
					&.past-head {
						background-color: $slr-yellow;
						color: $slr-gray-0;
						border-left: 3px solid $slr-orange;
					}
				}
			}
			ul {
				list-style: none;
				padding: 0px;
				margin: 0px;
				overflow-y: auto;
				height: 550px;
				li {
					border-bottom: 1px solid $slr-gray-1;
					padding: 5px 8px;
					display: grid;
					font-size: 13px;
					font-weight: 600;
					line-height: 20px;
					margin-bottom: 10px;
					color: $slr-black;
					background-color: $slr-white;
					border-radius: 5px;
					span {
						color: $slr-gray-2;
						font-weight: 400;
						margin-bottom: 5px;
						&.text-gray {
							color: $slr-gray-2;
							margin-bottom: 5px;
						}
						img {
							width: 16px;
							margin-right: 5px;
							vertical-align: sub;
						}
					}
				}
			}
		}
	}
}
// calendar end
